type TrackingFeatures = [TrackingFeature, ...ChildTrackingFeature[]];

export class FeatureTrackingData {
	constructor(
		private readonly name: TrackEventAction,
		private readonly features: TrackingFeatures,
	) {}
}

export interface TrackingFeatureLabels {
	[label: string]: string[];
}

export class TrackingFeature {
	constructor(
		protected readonly id: string,
		protected readonly name: string,
		protected readonly status: TrackEventStatus,
		protected labels: TrackingFeatureLabels,
		protected readonly position: number = 0,
	) {}

	addLabel(labelName: string, value: string) {
		this.labels = {
			...this.labels,
			[labelName]: [value],
		};
	}

	getId(): string {
		return this.id;
	}
}

export class ChildTrackingFeature extends TrackingFeature {
	constructor(
		override readonly id: string,
		override readonly name: string,
		override readonly status: TrackEventStatus,
		override readonly position: number,
		override readonly labels: TrackingFeatureLabels,
		readonly parentId: string,
	) {
		super(id, name, status, labels, position);
	}
}

export type TrackEventAction = "click" | "copy" | "close" | "open" | "scroll" | "next";
export type TrackEventStatus = "clicked" | "visible" | "loaded" | "hidden";

import type { ChildTrackingFeature, FeatureTrackingData, TrackingFeature } from "./FeatureTrackingData";
import { moduleLogger } from "./util/Logger";
import { eventQBus } from "@otto-ec/global-resources/event-q-bus";

const log = moduleLogger.scope("tracking");

export class TrackingService {
	sendEvent(payload: FeatureTrackingData) {
		log.info("Send 'event'", payload);
		eventQBus.emit("tracking.bct.submitAction", {}, payload);
	}

	sendMove(payload: FeatureTrackingData) {
		log.info(`Send 'move event'`, payload);
		eventQBus.emit("tracking.bct.submitAction", {}, payload); /*                                                     */
	}

	sendMergeForCinema(payload: (TrackingFeature | ChildTrackingFeature)[]) {
		log.info("Send 'merge for cinema'", payload);
		eventQBus.emit("tracking.bct.addFeaturesToPageImpression", payload);
	}
}
